module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images-datocms/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"eb67bceab631dc1ec53d6df0a32af9","maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dandelions.cloud","short_name":"dandelions.cloud","start_url":"/","background_color":"#262626","theme_color":"#262626","display":"minimal-ui","icon":"src/images/android-chrome-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4c990e9f34df6f321895d39440c230cf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7JNL3L758K"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
